import { motion } from "framer-motion";
import Subitle from "./subtitle";
import TextareaList from "./textareaList";

function InputBlockYear(props) {
  const dropIn = {
    hidden: {
      y: "30px",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "Inertia",
        delay: props.delay,
      },
    },
  };
  return (
    <motion.div
      className="input_block_year top_border"
      variants={dropIn}
      initial="hidden"
      animate="visible"
    >
      <div className="year_block">
        <Subitle content={props.title} />
        <input
          id="year"
          type="text"
          placeholder="חודש "
          defaultValue={props.period}
          onChange={(e) => {
            props.setperiod(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && e.target.value.length > 0) {
              props.onChange();
            }
          }}
        />
      </div>
      <TextareaList
        id={props.id}
        data={props.data}
        disabled={props.disabled}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </motion.div>
  );
}

export default InputBlockYear;
