import { motion } from "framer-motion";
import InputList from "./inputList";
import Subitle from "./subtitle";

function InputBlockSimple(props) {
  const dropIn = {
    hidden: {
      y: "30px",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "Inertia",
        delay: props.delay,
      },
    },
  };
  return (
    <motion.div
      className="input_block_simple"
      variants={dropIn}
      initial="hidden"
      animate="visible"
    >
      <Subitle content={props.title} />
      <InputList id={props.id} data={props.data} disabled={props.disabled} onChange={props.onChange} type={props.type} placeholder={props.placeholder}/>
    </motion.div>
  );
}

export default InputBlockSimple;
