import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Notification from "../Componets/notification";

import logo from "../Assets/Images/Logo.svg";
import CreateBoard from "../Componets/createBoard";
import ShareActions from "../Componets/shareActions";
import Share from "../Componets/share";
import { useSearchParams } from "react-router-dom";
import HeaderNotification from "../Componets/headerNotification";

function Header(props) {
  let DateDiff = {
    inSeconds: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return Math.floor((t2 - t1) / 1000);
    },

    inMinutes: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return Math.floor((t2 - t1) / (60 * 1000));
    },

    inHours: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return Math.floor((t2 - t1) / (3600 * 1000));
    },

    inDays: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return Math.floor((t2 - t1) / (24 * 3600 * 1000));
    },

    inWeeks: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
    },

    inMonths: function (d1, d2) {
      var d1Y = d1.getFullYear();
      var d2Y = d2.getFullYear();
      var d1M = d1.getMonth();
      var d2M = d2.getMonth();

      return d2M + 12 * d2Y - (d1M + 12 * d1Y);
    },

    inYears: function (d1, d2) {
      return d2.getFullYear() - d1.getFullYear();
    },
  };

  function lastUpdates(date) {
    if (date === "") {
      return "";
    }
    let d1 = new Date(date);
    let d2 = new Date();
    if (DateDiff.inYears(d1, d2) > 0) {
      return DateDiff.inYears(d1, d2) + " years ago";
    }
    if (DateDiff.inMonths(d1, d2) > 0) {
      return DateDiff.inMonths(d1, d2) + " months ago";
    }
    if (DateDiff.inDays(d1, d2) > 0) {
      return DateDiff.inDays(d1, d2) + " days ago";
    }
    if (DateDiff.inHours(d1, d2) > 0) {
      return DateDiff.inHours(d1, d2) + " hours ago";
    }
    if (DateDiff.inMinutes(d1, d2) > 0) {
      return DateDiff.inMinutes(d1, d2) + " minutes ago";
    }
    if (DateDiff.inSeconds(d1, d2) > 0) {
      return DateDiff.inSeconds(d1, d2) + " seconds ago";
    }
    if (DateDiff.inSeconds(d1, d2) === 0) {
      return" now";
    }
  }

  const notificationCloseTime = 3000;
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notMessage, setNotMessage] = useState("השינויים נשמרו");
  const [shareOpen, setShareOpen] = useState(false);
  const [createBoardView, setCreateBoardView] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => {
    setModalOpen(true);
    setCreateBoardView(true);
  };

  const notClose = () => setNotificationOpen(false);
  const notOpen = () => {
    setNotificationOpen(true);
    setTimeout(function () {
      setNotificationOpen(false);
    }, notificationCloseTime);
  };
  const changeMessage = (msg) => setNotMessage(msg);

  const shClose = () => setShareOpen(false);
  const shOpen = () => setShareOpen(true);

  const [searchParams, setSearchParams] = useSearchParams();

  if (
    searchParams.get("createboard") !== null &&
    !modalOpen &&
    !createBoardView
  ) {
    console.log("createboard", searchParams.get("createboard"));
    open();
  }

  // const color = props.color ? props.color : '#202020';
  let logo_dynamic = logo;
  if (props.logo) {
    logo_dynamic = props.logo;
  }

  const CreateButton = () => {
    return (
      <div>
        <motion.button
          className=""
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 1.1 }}
          onClick={() => (modalOpen ? close() : open())}
          style={{ background: props.color }}
        >
          צרו Planner בחינם
        </motion.button>
      </div>
    );
  };
  const SaveButton = () => {
    return (
      <div>
        <motion.button
          className="rtl_dir"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 1.1 }}
          onClick={() => {
            props.save(true);
            changeMessage("השינויים נשמרו");
            notificationOpen ? notClose() : notOpen();
          }}
          style={{ background: props.color }}
        >
          Save
        </motion.button>
      </div>
    );
  };

  return (
    <header className="header">
      {props.hSN && <HeaderNotification disableHSN={props.disableHSN}/>}
      <div>
        <div className="logo">
          <img src={logo_dynamic} alt="logo" />
        </div>
        <div className="description">
          {props.type !== "edit" && (
            <span>
              זו דוגמא ל Planner, בהקלקה על הכפתור תצרו לכם אחד עם הלוגו ושם
              החברה שלכם >>
            </span>
          )}
          {props.updateDate !== "" &&
            props.updateDate !== undefined &&
            props.type === "edit" && (
              <span className="update-date">
                Last updates {lastUpdates(props.updateDate)}
              </span>
            )}
        </div>
        <div className="actions">
          {props.type === "edit" && (
            <ShareActions
              onClick={shOpen}
              modalOpen={notificationOpen}
              handleClose={notClose}
              handleOpen={notOpen}
              changeMessage={changeMessage}
            />
          )}
          {props.type === "edit" ? <SaveButton /> : <CreateButton />}
        </div>
      </div>
      <AnimatePresence initial={false} mode="wait">
        {modalOpen && <CreateBoard modalOpen={modalOpen} handleClose={close} />}
      </AnimatePresence>
      <AnimatePresence initial={false} mode="wait">
        {notificationOpen && (
          <Notification
            modalOpen={notificationOpen}
            handleClose={notClose}
            message={notMessage}
          />
        )}
      </AnimatePresence>
      <AnimatePresence initial={false} mode="wait">
        {shareOpen && (
          <Share
            modalOpen={shareOpen}
            handleClose={shClose}
            handleOpen={notOpen}
            changeMessage={changeMessage}
          />
        )}
      </AnimatePresence>
    </header>
  );
}

export default Header;
