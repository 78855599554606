import { motion } from "framer-motion";
import { useState } from "react";

function Textarea(props) {
  let listItems;
  if (props.items) {
    listItems = props.items;
  } 
  const [item, setItem] = useState(["div"]);
  function addNewRow() {
    const current = [...item];
    current.push("newDiv");
    setItem(current);
  }
  return (
    <div>
      {listItems?.map((currentItem, index) => {
        return (
          <div className="put_value_area dot_before filled">
            <motion.textarea
              key={currentItem}
              id={`field-${index}`}
              type="text"
              placeholder={props.placeholder}
              whileFocus={{ scale: 1.2, x: "-20px", zIndex:1000 }}
              {...!props.disabled && 'autoFocus'}
              defaultValue ={currentItem}
              onChange={() => {}}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
                if (e.key === "Enter" && e.target.value.length > 0) {
                  e.preventDefault();
                  addNewRow();
                  e.target.parentElement.classList.add('filled');
                  props.onChange();
                }
              }}
              rows="3"
            ></motion.textarea>
          </div>
        );
      })}
      {item?.map((currentItem, index) => {
        return (
          <div className="put_value_area dot_before">
            <motion.textarea
              key={currentItem}
              id={`field-${index}`}
              type="text"
              placeholder={props.placeholder}
              whileFocus={{ scale: 1.2, x: "-20px" }}
              autoFocus
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
                if (e.key === "Enter" && e.target.value.length > 0) {
                  e.preventDefault();
                  addNewRow();
                  e.target.parentElement.classList.add('filled');
                  props.onChange();
                }
              }}
              rows="3"
            ></motion.textarea>
          </div>
        );
      })}
    </div>
  );
}

export default Textarea;
