function Footer() {
  return (
    <footer className="rtl_dir">
      <div>
        <span className="divider"></span>
        <div className="made_area">
          <span className="made_by">
            Made with <span style={{color: '#F43D33'}}>❤</span> By <a href="https://www.gemplan.co.il/" target='_blank' rel="noreferrer">GemPlan</a>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
