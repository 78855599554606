import { motion } from "framer-motion";
import { useState } from "react";

function Input(props) {
  const [item, setItem] = useState(["div"]);
  function addNewRow() {
    const current = [...item];
    current.push("newDiv");
    setItem(current);
  }

  let def_class = props.value && 'filled';

  return (
    <div>
      { item?.map((currentItem, index) => {
        return (
          <motion.div key={index} className={"put_value dot_before " + def_class } whileTap={{ scale: 1.2, x: "-20px", zIndex: 1000 }}>
            <motion.input
              key={`${props.id}-${index}`}
              // id={`${props.id}-field-${index}`}
              type="text"
              placeholder={props.placeholder}
              whileFocus={{ scale: 1.2, x: "-20px", zIndex: 1000 }}
              autoFocus
              defaultValue={props.value}
              onChange={() => {}}
              // disabled={props.disabled}
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.target.value.length > 0) {
                  addNewRow();
                  e.target.parentElement.classList.add('filled');
                  props.onChange();
                }
              }}
            />
          </motion.div>
        );
      })}
    </div>
  );
}

export default Input;