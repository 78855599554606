import { motion } from "framer-motion";

function Statement(props) {
  const dropIn = {
    hidden: {
      y: "5vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "Inertia",
        delay: 0.5,
      },
    },
  };
  const color = props.color ? props.color : '#202020';
  const Star = () => (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5719 12.0773C32.4272 11.622 32.1482 11.2211 31.7717 10.9271C31.3951 10.6331 30.9385 10.4597 30.4617 10.4297L21.8766 9.83711L18.6969 1.81562C18.5233 1.37394 18.221 0.994548 17.8294 0.726559C17.4377 0.458569 16.9746 0.314325 16.5 0.3125C16.0254 0.314325 15.5623 0.458569 15.1706 0.726559C14.779 0.994548 14.4767 1.37394 14.3031 1.81562L11.0656 9.88047L2.53828 10.4297C2.06206 10.4616 1.60637 10.6357 1.23018 10.9295C0.853982 11.2232 0.574583 11.6231 0.428125 12.0773C0.277727 12.5386 0.26894 13.0343 0.402897 13.5006C0.536854 13.9668 0.807412 14.3823 1.17969 14.6934L7.74141 20.2434L5.79024 27.918C5.65524 28.4372 5.67953 28.9849 5.85996 29.4901C6.04039 29.9953 6.3686 30.4346 6.80195 30.7508C7.22258 31.0527 7.72373 31.2222 8.24125 31.2376C8.75878 31.2531 9.26914 31.1138 9.70703 30.8375L16.4855 26.5449H16.5145L23.8133 31.1555C24.1877 31.3988 24.6242 31.5292 25.0707 31.5312C25.4353 31.5284 25.7944 31.442 26.1203 31.2786C26.4463 31.1153 26.7304 30.8794 26.951 30.5891C27.1715 30.2988 27.3225 29.9617 27.3925 29.6039C27.4624 29.2461 27.4493 28.877 27.3543 28.525L25.2875 20.1277L31.8203 14.6934C32.1926 14.3823 32.4631 13.9668 32.5971 13.5006C32.7311 13.0343 32.7223 12.5386 32.5719 12.0773Z"
        fill={color}
      />
    </svg>
  );

  return (
    <motion.div
      className={props.className}
      variants={dropIn}
      initial="hidden"
      animate="visible"
    >
      <Star />
      <div className="put_value">
            <motion.input
              key='statement'
              id='statement'
              type="text"
              placeholder="הצהרה חודשית"
              defaultValue={props.statement}
              onChange={(e) => {props.setStatement(e.target.value)}}
              whileFocus={{ scale: 1.2, x: "-20px" }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.target.value.length > 0) {
                  props.onChange();
                }
              }}
            />
          </div>
    </motion.div>
  );
}

export default Statement;
