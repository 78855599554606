import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import share from "../Assets/Images/share.svg";
import link from "../Assets/Images/link.svg";
import print from "../Assets/Images/print.svg";
import save from "../Assets/Images/save.svg";

function ShareActions(props) {
  // const [supportsPWA, setSupportsPWA] = useState(false);
  // const [promptInstall, setPromptInstall] = useState(null);

  // useEffect(() => {
  //   const handler = (e) => {
  //     e.preventDefault();
  //     console.log("we are being triggered :D");
  //     setSupportsPWA(true);
  //     setPromptInstall(e);
  //   };
  //   window.addEventListener("beforeinstallprompt", handler);

  //   return () => window.removeEventListener("transitionend", handler);
  // }, []);

  // function linkAction() {
  //   navigator.clipboard.writeText(window.location.href.split("?")[0]);
  //   props.changeMessage("copied to clipboard");
  //   props.handleOpen();
  // }

  // function desktopAction() {
  //   // evt.preventDefault();
  //   if (!promptInstall) {
  //     return;
  //   }
  //   promptInstall.prompt();
  // }
  function printAction() {
    window.print();
  }

  return (
    <div className="share_actions">
      {/* <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.1 }}
        onClick={desktopAction}
      >
        <img src={save} alt="save" />
        <span className="btn_title">save</span>
      </motion.div> */}
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.1 }}
        onClick={printAction}
      >
        <img src={print} alt="print" />
        <span className="btn_title">print</span>
      </motion.div>
      {/* <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.1 }}
        // onClick={() => console.log("share")}
        onClick={linkAction}
      >
        <img src={link} alt="link" />
        <span className="btn_title">link</span>
      </motion.div> */}
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.1 }}
        onClick={props.onClick}
      >
        <img src={share} alt="share" />
        <span className="btn_title">share</span>
      </motion.div>
    </div>
  );
}

export default ShareActions;
