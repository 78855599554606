import ReactDOM from "react-dom";
import { useState } from "react";
import { motion } from "framer-motion";
import Backdrop from "./backdrop";
import { HexColorPicker, HexColorInput } from "react-colorful";
import UploadFile from "./uploadFile";
import { db, storage } from "../firebase-config";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { sha256 } from "crypto-hash";
import { useNavigate } from "react-router-dom";

import close from "../Assets/Images/close.svg";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import emailjs from "@emailjs/browser";

function CreateBoard(props) {
  //   if (!open) return null;
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  const [newCompany, setNewCompany] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newLogo, setNewLogo] = useState(null);
  const [newColor, setNewColor] = useState("#202223");
  const [companyExist, setCompanyExist] = useState(false);

  const navigate = useNavigate();

  const checkCompanyName = async (e) => {
    e.preventDefault();
    const boardsRef = collection(db, "boards");

      const search_board = query(
        boardsRef,
        where("company", "==", newCompany)
      );

      const data = await getDocs(search_board).then();

      console.log('check company', data.docs);
      if(data.docs.length > 0){
        console.log('exist company');
        setCompanyExist(true);
      } else{
        console.log('not exist company');
        setCompanyExist(false);
        createWhiteBoard();
      }
  }
  function sendEmails(newCompany, newName, newEmail, hash) {
     
    var templateParams = {
      name: newName,
      email: newEmail,
      edit_link:
        window.location.origin +
        "/" +
        encodeURIComponent(newCompany) +
        "?hash=" +
        hash,
      link: window.location.origin + "/" + encodeURIComponent(newCompany),
    };
    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    );

    var templateParamsInner = {
      name: newName,
      email: newEmail,
      company: newCompany
    };
    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID_INNER,
      templateParamsInner,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    );
  }

  const createWhiteBoard = async (e) => {
    // e.preventDefault();

    const boardsRef = collection(db, "boards");
    console.log("post");
    let board_data = {
      company: newCompany,
      name: newName,
      email: newEmail,
      color: newColor,
      hash: await sha256(Date().toLocaleString()),
    };
    console.log("logo", newLogo);
    if (newLogo != null) {
      const imageRef = ref(storage, `logos/${newLogo[0].name + v4()}`);
      uploadBytes(imageRef, newLogo[0]).then((snapshot) => {
        getDownloadURL(imageRef).then((logoUrl) => {
          if (logoUrl) {
            board_data["logo"] = logoUrl;
            console.log(board_data);
            addDoc(boardsRef, board_data).then(() => {
              sendEmails(board_data.company, newName, newEmail, board_data.hash);
              navigate("/" + board_data.company + "?hash=" + board_data.hash);
            });
          }
        });
      });
    } else {
      addDoc(boardsRef, board_data).then(() => {
        sendEmails(board_data.company, newName, newEmail, board_data.hash);
        navigate("/" + board_data.company + "?hash=" + board_data.hash);
      });
    }
    console.log(board_data);
  };

  return ReactDOM.createPortal(
    <Backdrop onClickClose={props.handleClose}>
      <motion.div
        className="modal create_board"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="title_block">
          <img
            className="close"
            src={close}
            alt="close"
            onClick={props.handleClose}
          />
          <div className="title">
            שלכם מוכן <span className="bold">Planner</span> שני קליקים וה{" "}
          </div>
        </div>
        <form className="" onSubmit={checkCompanyName}>
          <div className="form_row">
            <label htmlFor="company_name">
              שם חברה - זה השם שיופיע בקישור של ה Planner שלכם
              <input
                id="company_name"
                className={companyExist && 'error'}
                type="text"
                placeholder="שם חברה"
                required
                onChange={(e) => {
                  setNewCompany(e.target.value);
                }}
              />
            </label>
            {companyExist && <span className="input_notification">שם החברה כבר בשימוש</span>}
          </div>
          <div className="form_row">
            <label htmlFor="name">
              שם מלא
              <input
                id="name"
                type="text"
                placeholder="שם מלא"
                required
                onChange={(e) => {
                  setNewName(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="form_row">
            <label htmlFor="email">
              אימייל - לשם ישלח את הקישור ל Planner
              <input
                id="email"
                type="email"
                placeholder="אימייל"
                required
                onChange={(e) => {
                  setNewEmail(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="form_columns">
            <div>
              <span className="column_title">לוגו</span>
              <UploadFile onChange={setNewLogo} />
            </div>
            <div>
              <span className="column_title">צבע מותג</span>
              <HexColorPicker color={newColor} onChange={setNewColor} />
              <div className="color_input_wrapper">
                <HexColorInput
                  className="color_input"
                  color={newColor}
                  onChange={setNewColor}
                />
                <div className="preview" style={{ background: newColor }}></div>
              </div>
            </div>
          </div>
          <motion.input
            className=""
            type="submit"
            value="צרו Planner בחינם"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1.1 }}
          />
        </form>
      </motion.div>
    </Backdrop>,
    document.getElementById("portal")
  );
}

export default CreateBoard;
