import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import checkmark from "../Assets/Images/checkmark.svg";
import close from "../Assets/Images/close_gray.svg";

function Notification(props) {
  const dropIn = {
    hidden: {
      y: "100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  return ReactDOM.createPortal(
  
    <motion.div
      className="modal notification"
      onClick={(e) => e.stopPropagation()}
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <img className="checkmark" src={checkmark} alt="checkmark" />
      <span>{props.message}</span>
      <img className="close" src={close} alt="close" onClick={props.handleClose} />
    </motion.div>,
    document.getElementById("portal")
  );
}

export default Notification;
