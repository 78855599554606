import logo_mobile from "../Assets/Images/Logo_mobile.svg";

function Mobile() {
  return (
    <section className="mobile">
      <p className="subtitle rtl_dir">A big screen is<br/>recommended for</p>
      <h2 className="title rtl_dir">big plans.</h2>
      <div className="switch">Switch to desktop</div>
      <img className="logo" src={logo_mobile} alt="logo" />
    </section>
  );
}

export default Mobile;
