import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Title from "../Componets/title";
import InputBlockSimple from "../Componets/inputBlockSimple";
import InputBlockMonth from "../Componets/inputBlockMonth";
import ReminderBlock from "../Componets/reminderBlock";
import InputBlockYear from "../Componets/inputBlockYear";
import Statement from "../Componets/statement";
import Notification from "../Componets/notification";
import { db } from "../firebase-config";
import { doc, updateDoc } from "firebase/firestore";

function Board(props) {
  const notificationCloseTime = 3000;
  const animationStep = 0.2;
  const dropIn = {
    hidden: {
      y: "5vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "Inertia",
        delay: 0,
      },
    },
  };

  let data = props.data;
  if (props.data === undefined) {
    data = {
      whydoing: [],
      partners: [],
      relationship: [],
      month: "",
      monthly: [],
      year: "",
      yearly: [],
      statement: "",
    };
  }
  // let defMonth = props.data.month;
  // console.log("props.data.month", props.data.month);
  // if (props.data.month === undefined) {
  //   defMonth = "";
  // }
  // console.log("props.data.month", defMonth);
  // let defYear = props.data.year;
  // if (props.data.year === undefined) {
  //   defYear = "";
  // }
  // let defStatement = props.data.statement;
  // if (props.data.statement === undefined) {
  //   defStatement = "";
  // }

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(
    props.type === "preview" ? true : false
  );
  const [saveData, setSaveData] = useState({});
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [statement, setStatement] = useState('');
  // useEffect(() => {
  //   setMonth(defMonth);
  // }, [month]);

  const close = () => setNotificationOpen(false);
  const open = () => {
    setNotificationOpen(true);
    setTimeout(function () {
      setNotificationOpen(false);
    }, notificationCloseTime);
  };

  const [date, setDate] = useState(null);

  const sendBoardData = async (id, data) => {
    const boardRef = doc(db, "boards", id);
    const currentDate = Date().toLocaleString();
    let board_data = {
      data: data,
      update_date: currentDate,
    };

    console.log("board_data send", id);
    console.log("board_data send", board_data);

    updateDoc(boardRef, board_data).then((response) => {
      setDate(currentDate);
      console.log("update doc", response);
    });
  };

  const handleChange = () => {
    console.log("save data to firebase");

    let whydoing = document.getElementById("whydoing");
    let inputs = whydoing.getElementsByTagName("input");
    let whydoing_data = [];
    Array.from(inputs).forEach((item) => {
      if (item.value !== "") {
        whydoing_data.push(item.value);
      }
    });

    let partners = document.getElementById("partners");
    inputs = partners.getElementsByTagName("input");
    let partners_data = [];
    Array.from(inputs).forEach((item) => {
      if (item.value !== "") {
        partners_data.push(item.value);
      }
    });

    let relationship = document.getElementById("relationship");
    inputs = relationship.getElementsByTagName("input");
    let relationship_data = [];
    Array.from(inputs).forEach((item) => {
      if (item.value !== "") {
        relationship_data.push(item.value);
      }
    });

    let monthly = document.getElementById("monthly");
    inputs = monthly.getElementsByTagName("input");
    let monthly_data = [];
    Array.from(inputs).forEach((item) => {
      if (item.value !== "") {
        monthly_data.push(item.value);
      }
    });

    let yearly = document.getElementById("yearly");
    inputs = yearly.getElementsByTagName("textarea");
    let yearly_data = [];
    Array.from(inputs).forEach((item) => {
      if (item.value !== "") {
        yearly_data.push(item.value);
      }
    });

    const board_data = {
      whydoing: whydoing_data,
      partners: partners_data,
      relationship: relationship_data,
      month: document.getElementById("month").value,
      monthly: monthly_data,
      year: document.getElementById("year").value,
      yearly: yearly_data,
      statement: document.getElementById("statement").value,
    };

    console.log("board_data", board_data);

    setSaveData(board_data);
    // useSaveBoardData(props.id,board_data);
    sendBoardData(props.id, board_data);
    // props.unsave(false);

    props.updateDate();
  };

  // console.log('save btn',props.save);
  // if(props.save){ handleChange() }
  useEffect(() => {
    if (props.save) {
      console.log(props.save, "- Has changed");
      handleChange();
    }
    // props.updateDate(Date().toLocaleString())
    // props.unsave(false);
  }, [props.save]);

  if(props.type === "preview"){
    // document.getElementById("whydoing").focus;
  }
  console.log("month board", month);

  return (
    <div>
      {props.updateDate}
      <motion.div
        className={"board " + props.type}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        type={props.type}
      >
        <div className="spirit">
          <Title content="כוונה" />
          <InputBlockSimple
            title="למה אנחנו עושים את זה?"
            id="whydoing"
            data={data.whydoing}
            delay={animationStep * 1}
            disabled={isDisabled}
            onChange={handleChange}
            type={props.type}
            placeholder="לדוגמא: לייצר ולאפשר צמיחה"
          />
          <InputBlockSimple
            title="ערכים שמנחים אותו"
            id="partners"
            data={data.partners}
            delay={animationStep * 2}
            disabled={isDisabled}
            onChange={handleChange}
            type={props.type}
            placeholder="לדוגמא: שקיפות"
          />
          <InputBlockSimple
            title="מה בסביבת העבודה שלנו"
            id="relationship"
            data={data.relationship}
            delay={animationStep * 3}
            disabled={isDisabled}
            onChange={handleChange}
            type={props.type}
            placeholder="לדוגמא: תקשורת בריאה"
          />
          <ReminderBlock
            title="תזכורת  1%."
            id="reminder"
            delay={animationStep * 4}
          />
        </div>
        <div className="actions">
          <Title content="פעולה" />
          <div className="columns">
            <div className="month">
              <InputBlockMonth
                title="מטרות חודשיות"
                id="monthly"
                period={data.month}
                setperiod={setMonth}
                data={data.monthly}
                delay={animationStep * 1}
                onChange={handleChange}
                disabled={isDisabled}
                placeholder="מטרה חודשית ..."
              />
            </div>
            <div className="year">
              <InputBlockYear
                title="יעדים שנתיים "
                id="yearly"
                period={data.year}
                setperiod={setYear}
                data={data.yearly}
                delay={animationStep * 1}
                onChange={handleChange}
                disabled={isDisabled}
                placeholder="יעד שנתי"
              />
            </div>
          </div>
        </div>
      </motion.div>
      <Statement
        className={"statement " + props.type}
        statement={data.statement}
        setStatement={setStatement}
        color={props.color}
        onChange={handleChange}
      />
      <AnimatePresence initial={false} mode="wait">
        {notificationOpen && (
          <Notification modalOpen={notificationOpen} handleClose={close} />
        )}
      </AnimatePresence>
    </div>
  );
}

export default Board;
