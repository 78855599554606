import "../Assets/Styles/App.scss";
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Board from "../Layouts/board";
import Container from "../Componets/container";

function Main() {
  const default_data = {
    whydoing: ["לייצר ולאפשר צמיחה"],
    partners: ["כנות", "מצויינות", "מסירות", "הקשבה", "אהבה"],
    relationship: ["שמחה", "יצירה", "מקצועיות ללא פשרות", "תקשורת בריאה"],
    month: "אוקטובר",
    monthly: ["מטרה חודשית ראשונה - 1.10", "מטרה חודשית שניה - 1.10","מטרה חודשית שלישית - 15.10", "מטרה חודשית ...","מטרה חודשית ...", "מטרה חודשית ...","מטרה חודשית ...", "מטרה חודשית ...","מטרה חודשית ...", "מטרה חודשית ..."],
    year: "2022",
    yearly: ["יעד שנתי", "יעד שנתי", "יעד שנתי", "יעד שנתי", "יעד שנתי", "יעד שנתי"],
    statement: "אופטימיות היא אסטרטגיה לעתיד טוב יותר, כי אם תאמין שיש עתיד טוב יותר, תפעל למענו",
  };

  return (
    <div>
      <Header />
      <Container>
        <Board data={default_data} type='preview'/>
      </Container>
      <Footer />
    </div>
  );
}

export default Main;
