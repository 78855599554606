import { useState } from "react";
import Input from "./input";

function InputList(props) {
  const [needAddInput, setAddInput] = useState(false);

  const add = () => setAddInput(true);
  
  let listItems;
  if (props.data) {
    listItems = props.data.map((item) => <Input id={props.id} value={item} />);
  }

  return (
    <div className="inputs_list" id={props.id}>
      {listItems}
      <Input id={props.id} addInput={add} disabled={props.disabled} onChange={props.onChange} type={props.type} placeholder={props.placeholder}/>
      {needAddInput && <Input addInput={add} />}
    </div>
  );
}

export default InputList;
