import "../Assets/Styles/App.scss";
import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import Board from "../Layouts/board";
import Container from "../Componets/container";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { db } from "../firebase-config";
import { useRef, useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";

function WhiteBoard(props) {
  const navigate = useNavigate();

  const params = useParams();
  // console.log("params", params);
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(searchParams.get("hash"));

  // console.log('header not11', localStorage.getItem('homescreenNotification'));
  const [logo, setLogo] = useState("");
  const [color, setColor] = useState("");
  const [hash, setHash] = useState("");
  const [boardId, setBoardID] = useState("");
  const [boardData, setBoardData] = useState("");
  const [boardUpdateDate, setBoardUpdateDate] = useState(null);
  const [saveButton, setSaveButton] = useState(false);
  const [homescreenNotification, setHomescreenNotification] = useState(localStorage.getItem('homescreenNotification') === 'false' ? false: true);

  useEffect(() => {
    localStorage.setItem('homescreenNotification', homescreenNotification);
  }, [homescreenNotification]);

  const [boards, setBoards] = useState([]);

  const setUpdateDate = () => setBoardUpdateDate(Date().toLocaleString());
  const setSaveBtn = d => setSaveButton(d);
  function disableHomescreenNotification(e) {
    // setHomescreenNotification(e);
  };

  // console.log('header not', homescreenNotification);

  useEffect(() => {
    const getBoards = async () => {
      const boardsRef = collection(db, "boards");

      const search_board = query(
        boardsRef,
        where("company", "==", params.company)
      );
      // console.log("search_board", search_board);

      const data = await getDocs(search_board);
      data.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        setColor(doc.data().color);
        setLogo(doc.data().logo);
        setHash(doc.data().hash);
        setBoardData(doc.data().data);
        setBoardUpdateDate(doc.data().update_date);
        setBoardID(doc.id);
      });
      console.log("boards", data.docs);
      setBoards(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      // console.log("boards", boards);
      if (data.docs.length === 0) {
        // console.log("boards not found", boards.length);
        navigate("/");
      }
    };

    getBoards();
  }, []);

  const timer = useRef(null); // we can save timer in useRef and pass it to child

  useEffect(() => {
    // useRef value stored in .current property
    // timer.current = setInterval(() => setBoardUpdateDate(boardUpdateDate), 10000);

  }, []);

  return (
    <div>
      <Header
        color={color}
        logo={logo}
        type={searchParams.get("hash") === hash ? "edit" : "preview"}
        updateDate={searchParams.get("hash") === hash ? boardUpdateDate : ""}
        save={setSaveButton}
        hSN={homescreenNotification}
        disableHSN={setHomescreenNotification}
      />
      <Container>
        {searchParams.get("hash") === hash ? (
          <Board
            id={boardId}
            data={boardData}
            color={color}
            type="edit"
            save={saveButton}
            updateDate={setUpdateDate}
            unsave={setSaveButton}
          />
        ) : (
          <Board data={boardData} color={color} type="preview" />
        )}
      </Container>
      <Footer />
    </div>
  );
}

export default WhiteBoard;
