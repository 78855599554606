import close from "../Assets/Images/close.svg";
import homescreen from "../Assets/Images/homescreen.png";

function HeaderNotification(props) {
return <div className="header_notification">
    <span>  שמרו קיצור דקך ל Planner מהדסקטופ. הקליקו על האייקון הזה: <img className="homescreen" src={homescreen} /> בשורת ה URL</span>
    <img
            className="close"
            src={close}
            alt="close"
            onClick={() => {props.disableHSN(false)}}
          />
</div>
}

export default HeaderNotification;