import { motion } from "framer-motion";
import Subitle from "./subtitle";

function ReminderBlock(props) {
  const dropIn = {
    hidden: {
      y: "30px",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "Inertia",
        delay: props.delay,
      },
    },
  };
  return (
    <motion.div
      className="input_block_simple top_border"
      variants={dropIn}
      initial="hidden"
      animate="visible"
    >
      <Subitle content={props.title} />
      <span className="reminer">1% כל יום = 365% בשנה</span>
    </motion.div>
  );
}

export default ReminderBlock;
