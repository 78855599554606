import React, { useState } from "react";
import Dropzone from "react-dropzone";


function UploadFile(props) {
  // const [imageUpload, setImageUpload] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const handleDrop = (acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name));
    props.onChange(acceptedFiles.map((file) => file));
  };
  // const getInputProps = {
  //   onChange={(e) => {props.onChange(e.target.files[0]);}}
  // }
  return (
    <div className="upload_file">
      <Dropzone
        onDrop={handleDrop}
        acceptedFiles=".svg,.png,.jpeg,.jpg"
        minSize={1024}
        maxSize={3072000}
        maxFiles={1}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
        }) => {
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
            ? "reject"
            : "";

          return (
            <div
              {...getRootProps({
                className: `dropzone ${additionalClass}`,
              })}
            >
              <input
                {...getInputProps()}
              />
              <span className="add_file_label">Add file</span>
              <p className="allowed_types">Accepts .svg, .jpg, and .png</p>
            </div>
          );
        }}
      </Dropzone>
      <div className="uploaded_name">
        <span>
          Uploaded file{" "}
          {fileNames.map((fileName) => (
            <span key={fileName}>{fileName}</span>
          ))}
        </span>
      </div>
    </div>
  );
}

export default UploadFile;
