import { useState } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import Backdrop from "./backdrop";
import { RadioButton } from "./radioButton";
import checkmark from "../Assets/Images/checkmark.svg";
import close from "../Assets/Images/close_gray.svg";
import whatsapp from "../Assets/Images/whatsapp.svg";
import copy from "../Assets/Images/copy.svg";
import linkedin from "../Assets/Images/linkedin.svg";
import facebook from "../Assets/Images/facebook.svg";
import more from "../Assets/Images/more.svg";

function Share(props) {
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  const [link, setLink] = useState(window.location.href.split("?")[0]);

  const radioChangeHandler = (e) => {
    setLink(e.target.value);
  };

  return ReactDOM.createPortal(
    <Backdrop onClickClose={props.handleClose}>
      <motion.div
        className="modal share"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="title_block">
          <img
            className="close"
            src={close}
            alt="close"
            onClick={props.handleClose}
          />
          <div className="title">בטח, שתפו!</div>
        </div>
        <div className="channels">
          <a
            className="channel"
            href={"whatsapp://send?text=ה Planner שלכם מחכה לכם - כנסו " + link}
            target="_blank"
          >
            <img className="whatsapp" src={whatsapp} alt="whatsapp" />
            <div className="title">Whatsapp</div>
          </a>
          <a
            className="channel"
            href={"https://www.linkedin.com/sharing/share-offsite/?url=ה Planner שלכם מחכה לכם - כנסו " + link}
            target="_blank"
          >
            <img className="linkedin" src={linkedin} alt="linkedin" />
            <div className="title">Linkedin</div>
          </a>
          <a
            className="channel"
            href={"https://www.facebook.com/sharer/sharer.php?u=ה Planner שלכם מחכה לכם - כנסו " + link}
            target="_blank"
          >
            <img className="facebook" src={facebook} alt="facebook" />
            <div className="title">Facebook</div>
          </a>
          {/* <div
            className="channel"
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
          >
            <img
              className="more"
              src={more}
              alt="more"
            />
            <div className="title">More</div>
          </div> */}
        </div>
        <div className="type">
          <div className="title">
            בחרו מה מקבל הלינק יוכל לעשות - לצפות או לערוך
          </div>
          <div className="radio-btn-container">
            <RadioButton
              changed={radioChangeHandler}
              id="1"
              isSelected={link === window.location.href.split("?")[0]}
              label="Viewer"
              value={window.location.href.split("?")[0]}
            />

            <RadioButton
              changed={radioChangeHandler}
              id="2"
              isSelected={link === window.location.href}
              label="Editor"
              value={window.location.href}
            />
          </div>
        </div>
        <div className="link">
          <img
            className="copy"
            src={copy}
            alt="copy"
            onClick={() => {
              navigator.clipboard.writeText(link);
              props.changeMessage("copied to clipboard");
              props.handleOpen();
            }}
          />
          <span className="current">{link}</span>
        </div>
      </motion.div>
    </Backdrop>,
    document.getElementById("portal")
  );
}

export default Share;
