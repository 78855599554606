import "./Assets/Styles/App.scss";
import Main from "./Pages/main";
import Mobile from "./Layouts/mobile";
// import { db } from "./firebase-config";
import { Routes, Route } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { collection, getDocs } from "firebase/firestore";

import useWindowDimensions from "./Hooks/useWindowDimensions";
import WhiteBoard from "./Pages/whiteBoard";
import ErrorNoFount from "./Componets/errorNoFount";

function App() {
  const mobile_breakpoint = 768;
  const { height, width } = useWindowDimensions();

  // const [boards, setBoards] = useState([]);

  // useEffect(() => {
  // const getBoards = async () => {
  //   const boardsRef = collection(db, "boards");
  //   const data = await getDocs(boardsRef);
  //   setBoards(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   console.log("boards", boards);
  // };

  // getBoards();
  // }, []);

  // const WhiteBoardWrapper = () => {
  //   const { company } = useParams();
  //   const board = boards.find((board) => board.company === company);
  //   console.log("WhiteBoardWrapper", boards);
  //   console.log("WhiteBoardWrapper", board);
  //   if (board != null) {
  //     <WhiteBoard color={board.color} logo={board.logo} />
  //     console.log("WhiteBoardWrapper");
  //   }
  //   return <WhiteBoard />;
  // };

  if (width >= mobile_breakpoint && height) {
    return (
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/:company" element={<WhiteBoard />} />
        <Route path='*' exact={true} element={<ErrorNoFount />} />
        {/* <Route path="/board/:company"
            element={({ match }) => <WhiteBoard board={boards[match.params.compony]} />}/>
          <Route element={<div>Post NOT FOUND!</div>}/> */}
      </Routes>
    );
  }
  return <Mobile />;
}

export default App;
