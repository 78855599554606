import { motion } from "framer-motion";
import InputList from "./inputList";
import Subitle from "./subtitle";

function InputBlockMonth(props) {
  const dropIn = {
    hidden: {
      y: "30px",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "Inertia",
        delay: props.delay,
      },
    },
  };
  console.log("month input", props.period);
  return (
    <motion.div
      className="input_block_simple top_border"
      variants={dropIn}
      initial="hidden"
      animate="visible"
    >
      <div className="month_block">
        <Subitle content={props.title} />
        <input
          id="month"
          type="text"
          placeholder="חודש "
          defaultValue={props.period}
          onChange={(e) => {
            props.setperiod(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && e.target.value.length > 0) {
              props.onChange();
            }
          }}
        />
      </div>
      <InputList
        id={props.id}
        data={props.data}
        disabled={props.disabled}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </motion.div>
  );
}

export default InputBlockMonth;
