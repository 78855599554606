import Textarea from "./textarea";

function TextareaList(props) {
  let listItems;
  if (props.data) {
    listItems = props.data;
  }
  return (
    <div className="textarea_list" id={props.id}>
        <Textarea items={listItems} onChange={props.onChange} disabled={props.disabled} placeholder={props.placeholder}/>
    </div>
  );
}

export default TextareaList;
